import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import FilterMultiSelect from "./FilterMultiSelect"


interface FilterSelect {
    title:string,
    setValue: (value: string[]) => void,
    value: string[],
    users: any[],
    useEmail: boolean,
    roles?: ('user' | 'super' | 'comercial' | 'admin')[]
}


const FilterUser = (props:FilterSelect) => {
    let { title, setValue, useEmail = false, value, users = [], roles = ['user', 'super', 'comercial', 'admin']} = props

    // let users = useSelector((state) => state.main.users)
    let userList = [...users]
        .sort((a, b) => a.name+a.lastname > b.name+b.lastname ? 1 : -1)
        if(roles && roles.length > 0){
            userList = users
                .filter((u) => u.roles.map((r) => roles.includes(r)).filter((t) => t ).length > 0)
        }

        // Remove userList duplicates
    userList = userList.filter((u, i) => userList.findIndex((uu) => uu._id == u._id) == i)

    return (
        <FilterMultiSelect
            {...props}
            idKey={useEmail ? 'email' : "_id"}
            list={userList} getName={(v) => v ? v.name + ' ' + v.lastname : ''}
            isEmptyAgent={true}
        />
    )
}

export default FilterUser