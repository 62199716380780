import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReportFilter } from "../../redux/slices/report";

interface FilterSelect {
  title: string;
  list: any[];
  idKey: string;
  getName: (valor: any) => string;
  setValue: (value: string[]) => void;
  forceTextValue?: string;
  value: string[];
  width?: string | number;
  disableMargin?: boolean;
  isEmptyAgent?: boolean
  buttons?: {
    title: string;
    active: boolean;
    action: () => void;
    icon?: JSX.Element;
  }[];
  useReportId?: [string, number];
}

const FilterMultiSelect = (params: FilterSelect) => {
  let {
    title,
    disableMargin,
    list,
    idKey,
    getName,
    isEmptyAgent = false,
    width,
    buttons,
    forceTextValue,
    useReportId,
  } = params;

  const dispatch = useDispatch();

  let setValue = (new_value) => {
    if (useReportId)
      dispatch(
        updateReportFilter({
          id: useReportId[0],
          filter: useReportId[1],
          value: new_value,
        })
      );
    if (params.setValue) params.setValue(new_value);
  };
  let value = useReportId
    ? useSelector((state: any) =>
      _.get(
        state,
        `report.reports.${useReportId[0]}.filters[${useReportId[1]}]`,
        ["all"]
      )
    )
    : params.value;

  const handle = (e: SelectChangeEvent<string[]>) => {
    const nuevo = e.target.value;
    let valor = typeof nuevo === "string" ? nuevo.split(",") : nuevo;

    if (valor.indexOf("all") > -1) {
      if (valor.length > 1 && value.length == 1) {
        setValue(
          [...list.filter((d) => valor.indexOf(d[idKey]) == -1).map((d) => d[idKey]), ...(isEmptyAgent ? valor.indexOf('value_empty') === -1 ? ['value_empty'] : [] : [])]
        );
      } else {
        setValue(["all"]);
      }
    } else {
      setValue(valor);
    }
  };

  return (
    <div>
      <FormControl
        sx={{
          width: width ? width : "200px",
          mr: 2,
          my: disableMargin ? 0 : 1,
        }}
        size="small"
      >
        <InputLabel>{title}</InputLabel>
        <Select
          id="demo-simple-selects"
          value={value}
          name={title}
          label={title}
          onChange={handle}
          size="small"
          color="primary"
          input={<OutlinedInput label={title} />}
          renderValue={(selected) =>
            forceTextValue
              ? forceTextValue
              : selected[0] == "all"
                ? "Todos"
                : selected
                  .map((s) =>
                    s == 'value_empty'
                      ? 'Sin vendedor'
                      :
                      getName(list[list.map((u) => u[idKey]).indexOf(s)])
                  )
                  .join(", ")
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
          multiple
        >
          <MenuItem
            key={"all"}
            value={"all"}
            sx={{ p: 0, width: width ? width : "100%" }}
          >
            <Checkbox
              size="small"
              sx={{ p: 1 }}
              checked={value.indexOf("all") > -1}
            />
            <ListItemText primary={"Todos"} />
          </MenuItem>
          {
            isEmptyAgent && (
              <MenuItem
                key={"value_empty"}
                value={"value_empty"}
                sx={{ p: 0, width: width ? width : "100%" }}
              >
                <Checkbox
                  size="small"
                  sx={{ p: 1 }}
                  checked={value.indexOf("all") > -1 || value.indexOf("value_empty") > -1}
                />
                <ListItemText primary={"Sin vendedor"} />
              </MenuItem>
            )
          }
          {list
            .filter((u) => u[idKey] != "all")
            .map((valor) => (
              <MenuItem
                key={valor[idKey]}
                value={valor[idKey]}
                sx={{ p: 0, width: width ? width : "100%" }}
              >
                <Checkbox
                  size="small"
                  sx={{ p: 1 }}
                  checked={
                    value.indexOf("all") > -1 ||
                    value.indexOf(valor[idKey]) > -1
                  }
                />
                <ListItemText primary={getName(valor)} />
              </MenuItem>
            ))}
          {buttons &&
            buttons.map((valor) => (
              <MenuItem
                sx={{ pl: 0, width: width ? width : "100%" }}
                onClick={valor.action}
              >
                {valor.icon && (
                  <ListItemIcon
                    sx={{
                      mr: 0,
                      pr: 0,
                      pl: 0.75,
                      minWidth: "inherit",
                      color: valor.active ? "#136DCD" : "inherit",
                    }}
                  >
                    {valor.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={valor.title}
                  sx={{ color: valor.active ? "#136DCD" : "inherit" }}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterMultiSelect;
